import styled from "styled-components"

export const Footer = styled.footer`
  width: 100%;
  height: 6rem;
  margin-top: 8rem;
  border-top: 2px solid rgba(234, 234, 234, 1);
  background-color: #e6ebf1;
  color: #0a2540;
  ul {
    width: 50%;
    display: flex;
    padding-top: 2rem;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    margin: 0;
    li {
      padding: 0 2rem;
      font-size: 0.8rem;
    }
    a:link {
      text-decoration: inherit;
      color: inherit;
      cursor: pointer;
    }
    a:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: pointer;
    }
  }
  @media (max-width: 800px) {
    ul {
      width: 100%;
      height: auto;
      flex-wrap: wrap;
      li {
        padding: 0 0.5rem;
        margin-bottom: 0;
      }
    }
  }
`
