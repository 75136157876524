import styled, { css } from "styled-components"

export const Navbar = styled.nav<{ isRoot?: boolean }>`
  position: absolute;
  top: 0;
  min-width: 100vw;
  width: 100%;
  height: 4rem;

  ${props =>
    !props.isRoot &&
    css`
      background: linear-gradient(
        270deg,
        rgba(234, 234, 234, 1) 0%,
        rgba(241, 241, 241, 1) 50%,
        rgba(27, 165, 131, 1) 50%,
        rgba(0, 128, 128, 1) 100%
      );

      box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.5);
      -webkit-box-shadow: 0px 5px 20px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow: 0px 5px 20px 0px rgba(50, 50, 50, 0.5);
    `}
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    li {
      flex-basis: 17%;
      text-align: center;
      font-size: 0.8rem;
      border-left: 2px solid black;
      padding: 0 1rem;
      font-weight: 600;
      z-index: 99;
      &:hover {
        text-shadow: 1px 1px black;
        opacity: 0.9;
      }
    }
    li:first-child {
      border: none;
      color: rgba(234, 234, 234, 1);
      border-color: rgba(234, 234, 234, 1);
    }
    li:nth-child(2) {
      color: rgba(234, 234, 234, 1);
      border-color: rgba(234, 234, 234, 1);
    }
    li:nth-child(4) {
      color: #008080;
      border-color: rgba(0, 128, 128, 1);
    }
    li:last-child {
      color: rgba(0, 128, 128, 1);
      border-color: rgba(0, 128, 128, 1);
    }
    .nav-header {
      font-size: 1.5rem;
      white-space: nowrap;
      font-weight: bolder;
      padding: 0 1.4rem;
      border-color: rgba(234, 234, 234, 1);
      color: rgba(0, 128, 128, 1);

      span:first-child {
        font-style: italic;
        font-weight: 400;
        font-size: 1.2rem;
        color: rgba(234, 234, 234, 1);
      }
      span:last-child {
        color: rgba(234, 234, 234, 1);
      }
    }
    a:link {
      text-decoration: inherit;
      color: inherit;
      cursor: pointer;
    }
    a:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: pointer;
    }
  }
`
export const NavbarSmall = styled(Navbar)`
  min-width: 0;
  max-width: 100vw;
  box-shadow: none;
  background: none !important;
  span {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    height: 2rem;
    width: 2rem;
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
  ul {
    padding: 2rem 0;
    flex-direction: column;
    justify-content: flex-start;
    background: linear-gradient(
      270deg,
      rgba(27, 165, 131, 1) 0%,
      rgba(0, 128, 128, 1) 100%
    );
    li {
      border-bottom: 1px solid white;
      border-left: none;
      text-align: left;
      width: 70%;
      color: white !important;
      border-color: white !important;
      z-index: 10;
    }
    .nav-header {
      display: none;
    }
  }
`
