import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Navbar, NavbarSmall } from "../style/navbar"
import { useLocation } from "@reach/router"

const Nav = () => {
  const location = useLocation()
  const isRoot = location.pathname === "/" ? true : false

  const [viewNav, toggleNav] = useState(false)

  const [isSmall, setSize] = useState(false)
  const isBrowser = () => typeof window !== "undefined"
  useEffect(() => {
    if (isBrowser()) {
      window.innerWidth <= 800 ? setSize(true) : setSize(false)
    }
  }, [])

  if (isSmall) {
    return (
      <NavbarSmall>
        {viewNav ? (
          <nav>
            <span onClick={() => toggleNav(!viewNav)}>
              <svg
                id="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                strokeWidth="1"
                stroke="white"
                fill="white"
              >
                <title>menu</title>
                <rect x="4" y="6" width="24" height="2" />
                <rect x="4" y="24" width="24" height="2" />
                <rect x="4" y="12" width="24" height="2" />
                <rect x="4" y="18" width="24" height="2" />
              </svg>
            </span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/thoughts/">Thoughts</Link>
              </li>
              <li>
                <Link to="/reviews/">Reviews</Link>
              </li>
              <li>
                <Link to="/exercises/">Exercise Collection</Link>
              </li>
            </ul>
          </nav>
        ) : (
          <div>
            <span onClick={() => toggleNav(!viewNav)}>
              <svg
                id="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                strokeWidth="1"
                stroke="black"
                fill="black"
              >
                <defs></defs>
                <title>menu</title>
                <rect x="4" y="6" width="24" height="2" />
                <rect x="4" y="24" width="24" height="2" />
                <rect x="4" y="12" width="24" height="2" />
                <rect x="4" y="18" width="24" height="2" />
              </svg>
            </span>
          </div>
        )}
      </NavbarSmall>
    )
  }

  return (
    <Navbar isRoot={isRoot}>
      <ul>
        <li>
          <Link to="/exercises/">Exercise Database</Link>
        </li>
        {/*<li>
          <Link to='/'>Workouts</Link>
        </li> */}
        <li>
          <Link to="/thoughts/">Thoughts</Link>
        </li>
        <li className="nav-header">
          <Link to="/">
            <span>get </span>
            <span>Fitne</span>ss Fluent
          </Link>
        </li>
        {/*<li>
          <Link to='/'>Nutrition</Link>
        </li>*/}
        <li>
          <Link to="/reviews/">Reviews</Link>
        </li>
        <li>
          <Link to="/">Home</Link>
        </li>
      </ul>
    </Navbar>
  )
}

export default Nav
