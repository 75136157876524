/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLocation } from "@reach/router"
//@ts-ignore
//import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

//import Header from "./header"
import "./layout.css"
import Nav from "./nav"
import { Footer } from "../style/footer"
//import CookieConsent from "react-cookie-consent"

//@ts-ignore
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      {/*<CookieConsent
        style={{ fontSize: "0.8rem" }}
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => initializeAndTrack(location)}
      >
        This website stores cookies on your computer. These cookies are used to
        collect information about how you interact with this website and allow
        us to remember you. We use this information in order to improve and
        customize your browsing experience and for analytics and metrics about
        our visitors on this website. <br />
        If you decline, your information won’t be tracked when you visit this
        website. A single cookie will be used in your browser to remember your
        preference not to be tracked.{" "}
      </CookieConsent> */}
      <Nav />
      <div>
        <main>{children}</main>
        <Footer>
          <ul>
            <li> © {new Date().getFullYear()} get Fitness Fluent</li>
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/about-us/">About Us</Link>
            </li>
            <li>
              <Link to="/">Home</Link>
            </li>
            {/*<li>Amazon Affiliate</li> */}
            {/*<li>Sitemap</li> */}
          </ul>
          {/* <p>
            getFitnessFluent is a participant in the Amazon Services LLC
            Associates Program.{" "}
            <Link to="/privacy-policy/">See more here.</Link>
          </p>*/}
        </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
